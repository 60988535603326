import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch } from "react-redux";

import { getLoggedInUser } from "../actions";
import "../styles/main.scss";
import ProtectedRoute from "./ProtectedRoute";
import LoginRedirect from "./LoginRedirect";

const Register = lazy(() => import("./Register"));
const Login = lazy(() => import("./Login"));
const ParticipantResetPassword = lazy(() => import("./Participant/ParticipantResetPassword"));
const ChangeParticipantPassword = lazy(() => import("./Participant/ChangeParticipantPassword"));
const UpdateNewPassword = lazy(() => import("./Participant/UpdateNewPassword"));
const AdminLogin = lazy(() => import("./Admin/AdminLogin"));
const AdminResetPassword = lazy(() => import("./Admin/AdminResetPassword"));
const ChangeAdminPassword = lazy(() => import("./Admin/ChangeAdminPassword"));
const AdminHome = lazy(() => import("./Admin/AdminHome"));
const IndividualDashboard = lazy(() => import("./Admin/IndividualDashboard"));
const ParticipantManagement = lazy(() => import("./Admin/ParticipantManagement"));
const VideoManagement = lazy(() => import("./Admin/VideoManagement"));
const NewEventForm = lazy(() => import("./Admin/NewEventForm"));
const UploadVideoForm = lazy(() => import("./Admin/UploadVideoForm"));
const ParticipantHome = lazy(() => import("./ParticipantHome"));
const About = lazy(() => import("./About"));
const ResourceList = lazy(() => import("./ResourceList"));
const Resource = lazy(() => import("./Resource"));
const Chatbox = lazy(() => import("./Participant/Chatbox"));
const Categories = lazy(() => import("./Categories"));
const Fitness = lazy(() => import("./Fitness"));
const AllFitness = lazy(() => import("./AllFitness"));
const IndividualFitnessVideo = lazy(() => import("./IndividualFitnessVideo"));
const RecipeList = lazy(() => import("./RecipeList"));
const Recipe = lazy(() => import("./Recipe"));
const IndividualVideo = lazy(() => import("./IndividualVideo"));
const IndividualCategory = lazy(() => import("./IndividualCategory"));

export default function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("ENVIRONMENT:", process.env.NODE_ENV);
        dispatch(getLoggedInUser());
    }, []);

    return (
        <BrowserRouter>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={true}
                closeButton={false}
                draggable={false}
            />
            <Suspense fallback={<div></div>}>
                {/* Public-facing pages */}
                <LoginRedirect path="/" exact component={Login} />
                <LoginRedirect path="/register" exact component={Register} />
                <LoginRedirect path="/reset-participant-password" exact component={ParticipantResetPassword} />
                <LoginRedirect path="/change-participant-password" exact component={ChangeParticipantPassword} />
                <LoginRedirect path="/admin-login" exact component={AdminLogin} />
                <LoginRedirect path="/reset-admin-password" exact component={AdminResetPassword} />
                <LoginRedirect path="/change-admin-password" exact component={ChangeAdminPassword} />
                {/* Content pages */}
                <ProtectedRoute
                    path="/participant-home"
                    exact
                    component={ParticipantHome}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/about"
                    exact
                    component={About}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/resources"
                    exact
                    component={ResourceList}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/resource/:resourceId"
                    exact
                    component={Resource}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/categories"
                    exact
                    component={Categories}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/fitness"
                    exact
                    component={Fitness}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/all-fitness"
                    exact
                    component={AllFitness}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/fitness/v/:videoId"
                    exact
                    component={IndividualFitnessVideo}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/recipes"
                    exact
                    component={RecipeList}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/c/:category"
                    exact
                    component={IndividualCategory}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/c/:category/:videoId"
                    exact
                    component={IndividualVideo}
                    authorizedRole={["Participant", "Admin"]}
                />
                <ProtectedRoute
                    path="/recipes/:recipeId"
                    exact
                    component={Recipe}
                    authorizedRole={["Participant", "Admin"]}
                />
                {/* Participant-only pages */}
                <ProtectedRoute
                    path="/chat"
                    exact
                    component={Chatbox}
                    authorizedRole={["Participant"]}
                />
                <ProtectedRoute
                    path="/new-login"
                    exact
                    component={UpdateNewPassword}
                    authorizedRole={["Participant"]}
                />
                {/* Admin pages */}
                <ProtectedRoute
                    path="/admin-home"
                    exact
                    component={AdminHome}
                    authorizedRole={["Admin"]}
                />
                <ProtectedRoute
                    path="/participant-data/:participantId"
                    exact
                    component={IndividualDashboard}
                    authorizedRole={["Admin"]}
                />
                <ProtectedRoute
                    path="/participant-management/:participantId"
                    exact
                    component={ParticipantManagement}
                    authorizedRole={["Admin"]}
                />
                <ProtectedRoute
                    path="/video-management"
                    exact
                    component={VideoManagement}
                    authorizedRole={["Admin"]}
                />
                <ProtectedRoute
                    path="/add-event"
                    exact
                    component={NewEventForm}
                    authorizedRole={["Admin"]}
                />
                <ProtectedRoute
                    path="/upload-video"
                    exact
                    component={UploadVideoForm}
                    authorizedRole={["Admin"]}
                />
            </Suspense>
        </BrowserRouter>
    );
}