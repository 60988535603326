import axios from "axios";

const protocol = window.location.protocol;
const hostname = window.location.hostname;

export const AUTH_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/auth`,
});

export const USER_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/user`,
});

export const METRIC_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/metric`,
});

export const CHAT_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/chat`,
});

export const EXCEL_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/excel`,
});

export const VIDEO_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/video`,
});

export const RESOURCE_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/resource`,
});

export const CALENDAR_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/calendar`,
});