/* eslint-disable import/no-anonymous-default-export */
import {
    AUTHENTICATE_USER,
    LOGIN_USER,
    GET_LOGGED_IN_USER,
    LOGOUT_USER,
} from "../actions/types";

export default (
    state = { isLoading: true, isLoggedIn: false, userInfo: null },
    action
) => {
    switch (action.type) {
        case AUTHENTICATE_USER:
            if (action.payload.success) {
                return {
                    success: true,
                    userInfo: action.payload.userInfo,
                };
            }
            return action.payload;
        case LOGIN_USER:
            const { success, userInfo } = state;
            return { isLoading: false, isLoggedIn: success, userInfo };
        case GET_LOGGED_IN_USER:
            return { ...action.payload, isLoading: false };
        case LOGOUT_USER:
            return { ...action.payload, isLoading: false, userInfo: null };
        default:
            return state;
    }
};