import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function LoginRedirect({
    component: Component,
    ...otherProps
}) {
    const auth = useSelector((state) => state.auth);
    return (
        <Route
            {...otherProps}
            render={(props) => {
                if (!auth.isLoading) {
                    if (!auth.isLoggedIn) {
                        return <Component {...props} />;
                    } else {
                        const { role, id } = auth.userInfo;
                        let homeURL;
                        if (role === "Admin") {
                            homeURL = "/admin-home";
                        } else {
                            homeURL = "/participant-home";
                        }
                        return <Redirect to={{pathname: homeURL }} />;
                    }
                } else {
                    return null;
                }
            }}
        />
    );
}