import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function ProtectedRoute({
    authorizedRole,
    component: Component,
    ...otherProps
}) {
    const auth = useSelector((state) => state.auth);
    return (
        <Route
            {...otherProps}
            render={(props) => {
                if (!auth.isLoading) {
                    if (auth.isLoggedIn && authorizedRole.includes(auth.userInfo.role)) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect to={{pathname: "/", state: { from: props.location } }} />;
                    }
                } else {
                    return null;
                }
            }}
        />
    );
}